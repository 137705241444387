/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AdminImportJobEntity from 'Models/Security/Acl/AdminImportJobEntity';
import VisitorsImportJobEntity from 'Models/Security/Acl/VisitorsImportJobEntity';
import InstitutionAdminImportJobEntity from 'Models/Security/Acl/InstitutionAdminImportJobEntity';
import UserImportJobEntity from 'Models/Security/Acl/UserImportJobEntity';
import StudentAdvisorImportJobEntity from 'Models/Security/Acl/StudentAdvisorImportJobEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { FileListPreview } from 'Views/Components/CRUD/Attributes/AttributeFile';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IImportJobEntityAttributes extends IModelAttributes {
	totalRowCount: number;
	importType: Enums.importType;
	importStatus: Enums.importStatus;
	contentId: string;
	content: Blob;
	fileName: string;

	importJobErrorss: Array<
		| Models.ImportJobErrorEntity
		| Models.IImportJobErrorEntityAttributes
	>;
	institutionId: string;
	institution: Models.InstitutionEntity | Models.IInstitutionEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ImportJobEntity', 'Import Job')
// % protected region % [Customise your entity metadata here] end
export default class ImportJobEntity extends Model
	implements IImportJobEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AdminImportJobEntity(),
		new VisitorsImportJobEntity(),
		new InstitutionAdminImportJobEntity(),
		new UserImportJobEntity(),
		new StudentAdvisorImportJobEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Total Row Count'] off begin
	/**
	 * Total number of rows in CSV file
	 */
	@Validators.Required()
	@Validators.Integer()
	@observable
	@attribute<ImportJobEntity, number>()
	@CRUD({
		name: 'Total Row Count',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public totalRowCount: number;
	// % protected region % [Modify props to the crud options here for attribute 'Total Row Count'] end

	// % protected region % [Modify props to the crud options here for attribute 'Import Type'] off begin
	/**
	 * Import type. The type of entity that the job is importion. Currently support only the types that are in ImportType enum
	 */
	@Validators.Required()
	@observable
	@attribute<ImportJobEntity, Enums.importType>()
	@CRUD({
		name: 'Import Type',
		displayType: 'enum-combobox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.importTypeOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.importTypeOptions),
		displayFunction: (attr: Enums.importType) => Enums.importTypeOptions[attr],
	})
	public importType: Enums.importType;
	// % protected region % [Modify props to the crud options here for attribute 'Import Type'] end

	// % protected region % [Modify props to the crud options here for attribute 'Import Status'] off begin
	/**
	 * The status represents the process of importing programs or courses from a CSV file. The status will change in the following order. Processing, ready for review, uploading, complete.  The status is set to 'error' state if anything fails during the import
	 */
	@Validators.Required()
	@observable
	@attribute<ImportJobEntity, Enums.importStatus>()
	@CRUD({
		name: 'Import Status',
		displayType: 'enum-combobox',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.importStatusOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.importStatusOptions),
		displayFunction: (attr: Enums.importStatus) => Enums.importStatusOptions[attr],
	})
	public importStatus: Enums.importStatus;
	// % protected region % [Modify props to the crud options here for attribute 'Import Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'Content'] off begin
	/**
	 * The content of the file
	 */
	@Validators.Required()
	@observable
	@attribute<ImportJobEntity, string>({ file: 'content' })
	@CRUD({
		name: 'Content',
		displayType: 'file',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		fileAttribute: 'content',
		displayFunction: attr => attr
			? <FileListPreview url={attr} />
			: 'No File Attached',
	})
	public contentId: string;

	@observable
	public content: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Content'] end

	// % protected region % [Modify props to the crud options here for attribute 'File name'] off begin
	/**
	 * The name of the file that are being uploaded. There are no restrictions on what name the users can set
	 */
	@Validators.Required()
	@observable
	@attribute<ImportJobEntity, string>()
	@CRUD({
		name: 'File name',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public fileName: string;
	// % protected region % [Modify props to the crud options here for attribute 'File name'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Import Job Errors'] off begin
		name: 'Import Job Errorss',
		displayType: 'reference-multicombobox',
		order: 60,
		referenceTypeFunc: () => Models.ImportJobErrorEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'importJobErrorss',
			oppositeEntity: () => Models.ImportJobErrorEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Import Job Errors'] end
	})
	public importJobErrorss: Models.ImportJobErrorEntity[] = [];

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Institution'] off begin
		name: 'Institution',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.InstitutionEntity,
		// % protected region % [Modify props to the crud options here for reference 'Institution'] end
	})
	public institutionId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public institution: Models.InstitutionEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IImportJobEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IImportJobEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.totalRowCount !== undefined) {
				this.totalRowCount = attributes.totalRowCount;
			}
			if (attributes.importType !== undefined) {
				this.importType = attributes.importType;
			}
			if (attributes.importStatus !== undefined) {
				this.importStatus = attributes.importStatus;
			}
			if (attributes.content !== undefined) {
				this.content = attributes.content;
			}
			if (attributes.contentId !== undefined) {
				this.contentId = attributes.contentId;
			}
			if (attributes.fileName !== undefined) {
				this.fileName = attributes.fileName;
			}
			if (attributes.importJobErrorss !== undefined && Array.isArray(attributes.importJobErrorss)) {
				for (const model of attributes.importJobErrorss) {
					if (model instanceof Models.ImportJobErrorEntity) {
						this.importJobErrorss.push(model);
					} else {
						this.importJobErrorss.push(new Models.ImportJobErrorEntity(model));
					}
				}
			}
			if (attributes.institutionId !== undefined) {
				this.institutionId = attributes.institutionId;
			}
			if (attributes.institution !== undefined) {
				if (attributes.institution === null) {
					this.institution = attributes.institution;
				} else if (attributes.institution instanceof Models.InstitutionEntity) {
					this.institution = attributes.institution;
					this.institutionId = attributes.institution.id;
				} else {
					this.institution = new Models.InstitutionEntity(attributes.institution);
					this.institutionId = this.institution.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		importJobErrorss {
			${Models.ImportJobErrorEntity.getAttributes().join('\n')}
		}
		institution {
			${Models.InstitutionEntity.getAttributes().join('\n')}
			${Models.InstitutionEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			importJobErrorss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
				contentType: 'multipart/form-data',
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ImportJobEntity.prototype, 'created');
CRUD(modifiedAttr)(ImportJobEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
