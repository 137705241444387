/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AdminImportJobErrorEntity from 'Models/Security/Acl/AdminImportJobErrorEntity';
import UserImportJobErrorEntity from 'Models/Security/Acl/UserImportJobErrorEntity';
import InstitutionAdminImportJobErrorEntity from 'Models/Security/Acl/InstitutionAdminImportJobErrorEntity';
import VisitorsImportJobErrorEntity from 'Models/Security/Acl/VisitorsImportJobErrorEntity';
import StudentAdvisorImportJobErrorEntity from 'Models/Security/Acl/StudentAdvisorImportJobErrorEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IImportJobErrorEntityAttributes extends IModelAttributes {
	row: number;
	columnName: string;
	errorMessage: string;

	importJobId: string;
	importJob: Models.ImportJobEntity | Models.IImportJobEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ImportJobErrorEntity', 'Import Job Error')
// % protected region % [Customise your entity metadata here] end
export default class ImportJobErrorEntity extends Model
	implements IImportJobErrorEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AdminImportJobErrorEntity(),
		new UserImportJobErrorEntity(),
		new InstitutionAdminImportJobErrorEntity(),
		new VisitorsImportJobErrorEntity(),
		new StudentAdvisorImportJobErrorEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Row'] off begin
	/**
	 * Row number
	 */
	@Validators.Required()
	@Validators.Integer()
	@observable
	@attribute<ImportJobErrorEntity, number>()
	@CRUD({
		name: 'Row',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public row: number;
	// % protected region % [Modify props to the crud options here for attribute 'Row'] end

	// % protected region % [Modify props to the crud options here for attribute 'Column Name'] off begin
	/**
	 * Column name in CSV file of the error message
	 */
	@Validators.Required()
	@observable
	@attribute<ImportJobErrorEntity, string>()
	@CRUD({
		name: 'Column Name',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public columnName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Column Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Error Message'] off begin
	/**
	 * The error message if a row fails validation.  This message will be shown to the user on the front end
	 */
	@Validators.Required()
	@observable
	@attribute<ImportJobErrorEntity, string>()
	@CRUD({
		name: 'Error Message',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public errorMessage: string;
	// % protected region % [Modify props to the crud options here for attribute 'Error Message'] end

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Import Job'] off begin
		name: 'Import Job',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.ImportJobEntity,
		// % protected region % [Modify props to the crud options here for reference 'Import Job'] end
	})
	public importJobId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public importJob: Models.ImportJobEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IImportJobErrorEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IImportJobErrorEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.row !== undefined) {
				this.row = attributes.row;
			}
			if (attributes.columnName !== undefined) {
				this.columnName = attributes.columnName;
			}
			if (attributes.errorMessage !== undefined) {
				this.errorMessage = attributes.errorMessage;
			}
			if (attributes.importJobId !== undefined) {
				this.importJobId = attributes.importJobId;
			}
			if (attributes.importJob !== undefined) {
				if (attributes.importJob === null) {
					this.importJob = attributes.importJob;
				} else if (attributes.importJob instanceof Models.ImportJobEntity) {
					this.importJob = attributes.importJob;
					this.importJobId = attributes.importJob.id;
				} else {
					this.importJob = new Models.ImportJobEntity(attributes.importJob);
					this.importJobId = this.importJob.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		importJob {
			${Models.ImportJobEntity.getAttributes().join('\n')}
			${Models.ImportJobEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ImportJobErrorEntity.prototype, 'created');
CRUD(modifiedAttr)(ImportJobErrorEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
