export type DataIndex<Out> = Record<string | number, Out>;

type Mapper<In, Out> = (
	item: In,
	existing: Out | undefined
) => Out;

type IndexFunction = <In>(
	list: In[],
	property: keyof In | ((item: In) => string | number)
) => DataIndex<In>;

type IndexFunctionArray = <In>(
	list: In[],
	property: keyof In | ((item: In) => string | number)
) => DataIndex<In[]>;

type IndexFunctionMutate = <In, Out>(
	list: In[],
	property: keyof In | ((item: In) => string | number),
	mapper: Mapper<In, Out>
) => DataIndex<Out>;

export const indexByProperty: IndexFunction = (list, property) => {
	return indexByPropertyMutate(list, property, item => item);
};

export const indexByPropertyArray: IndexFunctionArray = (list, property) => (
	indexByPropertyMutate<typeof list[number], typeof list>(
		list,
		property,
		(item, existingArrayInIndex) => (
			existingArrayInIndex
				? [...existingArrayInIndex, item]
				: [item]
		),
	)
);

export const indexByPropertyMutate: IndexFunctionMutate = (list, property, mapper) => {
	const outputIndex: DataIndex<ReturnType<typeof mapper>> = {};

	for (const item of list) {
		const indexValue = typeof property === 'function'
			? property(item)
			: item[property];

		if (indexValue === undefined) {
			throw new Error('Index value can not be undefined');
		}
		if (typeof indexValue !== 'string' && typeof indexValue !== 'number') {
			throw new Error(`Cannot index by non string or number value: \`${indexValue}\` is ${typeof indexValue}`);
		}

		outputIndex[indexValue] = mapper(item, outputIndex[indexValue]);
	}
	return outputIndex;
};

export const flattenIndexToIdArray = <T, >(offeringIndex: DataIndex<T>) => (
	Object.keys(offeringIndex)
		.filter(offeringId => offeringIndex[offeringId])
);

export const reduceList = (list: any[], accessor?: (item: typeof list[number]) => any) => (
	list.reduce(
		(acc, curr) => ({
			...acc,
			...(accessor?.(curr) || curr),
		}),
		{},
	)
);
