/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AdminCourseLevelEntity from 'Models/Security/Acl/AdminCourseLevelEntity';
import VisitorsCourseLevelEntity from 'Models/Security/Acl/VisitorsCourseLevelEntity';
import InstitutionAdminCourseLevelEntity from 'Models/Security/Acl/InstitutionAdminCourseLevelEntity';
import UserCourseLevelEntity from 'Models/Security/Acl/UserCourseLevelEntity';
import StudentAdvisorCourseLevelEntity from 'Models/Security/Acl/StudentAdvisorCourseLevelEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ICourseLevelEntityAttributes extends IModelAttributes {
	level: string;

	institutionId: string;
	institution: Models.InstitutionEntity | Models.IInstitutionEntityAttributes;
	courses: Array<
		| Models.CourseEntity
		| Models.ICourseEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('CourseLevelEntity', 'Course Level')
// % protected region % [Customise your entity metadata here] end
export default class CourseLevelEntity extends Model
	implements ICourseLevelEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AdminCourseLevelEntity(),
		new VisitorsCourseLevelEntity(),
		new InstitutionAdminCourseLevelEntity(),
		new UserCourseLevelEntity(),
		new StudentAdvisorCourseLevelEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Level'] off begin
	/**
	 * eg, 100, 200, 300
	 */
	@Validators.Required()
	@observable
	@attribute<CourseLevelEntity, string>()
	@CRUD({
		name: 'Level',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public level: string;
	// % protected region % [Modify props to the crud options here for attribute 'Level'] end

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Institution'] off begin
		name: 'Institution',
		displayType: 'reference-combobox',
		order: 20,
		referenceTypeFunc: () => Models.InstitutionEntity,
		// % protected region % [Modify props to the crud options here for reference 'Institution'] end
	})
	public institutionId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public institution: Models.InstitutionEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Course'] on begin
		name: 'Courses',
		displayType: 'hidden',
		order: 20,
		referenceTypeFunc: () => Models.CourseEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'courses',
			oppositeEntity: () => Models.CourseEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Course'] end
	})
	public courses: Models.CourseEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ICourseLevelEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ICourseLevelEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.level !== undefined) {
				this.level = attributes.level;
			}
			if (attributes.institutionId !== undefined) {
				this.institutionId = attributes.institutionId;
			}
			if (attributes.institution !== undefined) {
				if (attributes.institution === null) {
					this.institution = attributes.institution;
				} else if (attributes.institution instanceof Models.InstitutionEntity) {
					this.institution = attributes.institution;
					this.institutionId = attributes.institution.id;
				} else {
					this.institution = new Models.InstitutionEntity(attributes.institution);
					this.institutionId = this.institution.id;
				}
			}
			if (attributes.courses !== undefined && Array.isArray(attributes.courses)) {
				for (const model of attributes.courses) {
					if (model instanceof Models.CourseEntity) {
						this.courses.push(model);
					} else {
						this.courses.push(new Models.CourseEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		institution {
			${Models.InstitutionEntity.getAttributes().join('\n')}
			${Models.InstitutionEntity.getFiles().map(f => f.name).join('\n')}
		}
		courses {
			${Models.CourseEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			courses: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'courses',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.level;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(CourseLevelEntity.prototype, 'created');
CRUD(modifiedAttr)(CourseLevelEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
