/* eslint-disable no-bitwise */

interface RgbaColor {
	r: number
	g: number
	b: number
	a: number
}

function padZero(str: string, length: number = 2) {
	const zeros = new Array(length).join('0');
    // from the end of the string, trim to the desired length
	return (zeros + str).slice(-length);
}

// eslint-disable-next-line max-len
// Original post: https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)#stackoverflow-archive-begin
/**
 *
 * This will take a HEX or RGB web color. pSBC can shade it darker or lighter, or blend it with a second color,
 * and can also pass it right through but convert from Hex to RGB (Hex2RGB) or RGB to Hex (RGB2Hex).
 * All without you even knowing what color format you are using.
 *
 * @param amount - amount of blending and shading. Range from -1.0 to 1.0
 * @param fromColour - The colour that to start transforming the colour
 * @param toColour - Optional - The colour to transform to
 * @param useLinearBlending Optional - Use Linear blending or Log blending
 *
 * @returns Blended hex value
 */
export const shadeAndBlendColours = (
	amount: number,
	fromColour: string,
	toColour?: string,
	useLinearBlending?: boolean,
) => {
	let r: number;
	let g: number;
	let b: number;
	const isBlending = typeof (toColour) === 'string';

	// Test for invalid inputs
	if (
		amount < -1
		|| amount > 1
		|| (fromColour[0] !== 'r' && fromColour[0] !== '#')
		|| (toColour && !isBlending)
	) {
		return null;
	}

	let convertToRgb = fromColour.length > 9;

	// If blending 2 colours and the `toColour` is in RGB format or `toColour` has value 'c'
	// set output colour format to RGB
	if (isBlending && toColour) {
		if (toColour.length > 9) {
			convertToRgb = true;
		} else if (toColour === 'c') {
			convertToRgb = !convertToRgb;
		} else {
			convertToRgb = false;
		}
	}

	// Convert `fromColour` to RGB format
	const fromColourRgb = convertHexToRgba(fromColour);

	let toColourRgb: RgbaColor | null;

	// Convert `toColour` to RGB format
	// if `toColour` is undefined, return
	//    black - when `amount` is less than 0
	//    white - when `amount` is equal or greater than 0
	if (toColour && toColour !== 'c') {
		toColourRgb = convertHexToRgba(toColour);
	} else if (amount < 0) {
		toColourRgb = {
			r: 0, g: 0, b: 0, a: -1,
		};
	} else {
		toColourRgb = {
			r: 255, g: 255, b: 255, a: -1,
		};
	}

	const convertAmount: number = amount < 0 ? amount * -1 : amount;
	const P = 1 - convertAmount; // I am not sure what P is, sorry

	if (!fromColourRgb || !toColourRgb) return null;

	// Calculate the output colour
	if (useLinearBlending) {
		r = Math.round(P * fromColourRgb.r + convertAmount * toColourRgb.r);
		b = Math.round(P * fromColourRgb.b + convertAmount * toColourRgb.b);
		g = Math.round(P * fromColourRgb.g + convertAmount * toColourRgb.g);
	} else {
		r = Math.round((P * fromColourRgb.r ** 2 + convertAmount * toColourRgb.r ** 2) ** 0.5);
		g = Math.round((P * fromColourRgb.g ** 2 + convertAmount * toColourRgb.g ** 2) ** 0.5);
		b = Math.round((P * fromColourRgb.b ** 2 + convertAmount * toColourRgb.b ** 2) ** 0.5);
	}

	const { a } = fromColourRgb;
	const toColourAlphaValue = toColourRgb.a;
	const addAlphaValue = a >= 0 || toColourAlphaValue >= 0;
	let alphaValue: number = 0;

	// Check if the colours contain the Alpha channel, if yes then input the Alpha channel in the output
	if (addAlphaValue) {
		if (a < 0) {
			alphaValue = toColourAlphaValue;
		} else if (toColourAlphaValue < 0) {
			alphaValue = a;
		} else {
			alphaValue = a * P + toColourAlphaValue * convertAmount;
		}
	}

	// Output in RGB format
	if (convertToRgb) {
		// eslint-disable-next-line max-len
		return `rgb${addAlphaValue ? 'a(' : '('}${r},${g},${b}${addAlphaValue ? `,${Math.round(a * 1000) / 1000}` : ''})`;
	}

	// Output in Hex format
	return convertRgbaToHex(r, g, b, addAlphaValue ? alphaValue : undefined);
};

const convertRgbaToHex = (r: number, g: number, b:number, a?:number) => {
	// eslint-disable-next-line max-len
	return `#${(4294967296 + r * 16777216 + g * 65536 + b * 256 + (a ? Math.round(a * 255) : 0)).toString(16).slice(1, a ? undefined : -2)}`;
};

const convertHexToRgba = (colour: string): RgbaColor | null => {
	let n = colour.length;

	const x = {} as RgbaColor;

	if (n > 9) {
		const dBreakdown = colour.split(',');
		const [r, g, b, a] = dBreakdown;
		n = dBreakdown.length;

		if (n < 3 || n > 4) return null;

		x.r = parseInt(r[3] === 'a' ? r.slice(5) : r.slice(4), 10);
		x.g = parseInt(g, 10);
		x.b = parseInt(b, 10);
		x.a = a ? parseFloat(a) : -1;
	} else {
		let hexCode = colour;
		if (n === 8 || n === 6 || n < 4) {
			return null;
		}

		if (n < 6) {
			// eslint-disable-next-line max-len
			hexCode = `#${colour[1]}${colour[1]}${colour[2]}${colour[2]}${colour[3]}${colour[3]}${n > 4 ? colour[4] + colour[4] : ''}`;
		}

		const hexCodeWithoutHashChar = parseInt(hexCode.slice(1), 16);

		if (n === 9 || n === 5) {
			x.r = (hexCodeWithoutHashChar >> 24) & 255;
			x.g = (hexCodeWithoutHashChar >> 16) & 255;
			x.b = (hexCodeWithoutHashChar >> 8) & 255;
			x.a = Math.round((hexCodeWithoutHashChar & 255) / 0.255) / 1000;
		} else {
			x.r = hexCodeWithoutHashChar >> 16;
			x.g = (hexCodeWithoutHashChar >> 8) & 255;
			x.b = hexCodeWithoutHashChar & 255;
			x.a = -1;
		}
	}

	return x;
};

export default function invertColor(inputHex: string, isBlackAndWhite: boolean) {
	let hex = inputHex;
	if (hex.indexOf('#') === 0) {
		hex = hex.slice(1);
	}
	// convert 3-digit hex to 6-digits.
	if (hex.length === 3) {
		hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
	}
	if (hex.length !== 6) {
		throw new Error('Invalid HEX color.');
	}
	const rInt = parseInt(hex.slice(0, 2), 16);
	const gInt = parseInt(hex.slice(2, 4), 16);
	const bInt = parseInt(hex.slice(4, 6), 16);

	if (isBlackAndWhite) {
		// https://stackoverflow.com/a/3943023/112731
		return (rInt * 0.299 + gInt * 0.587 + bInt * 0.114) > 186
			? '#000000'
			: '#FFFFFF';
	}
	// invert color components
	const r = (255 - rInt).toString(16);
	const g = (255 - gInt).toString(16);
	const b = (255 - bInt).toString(16);
	// pad each with zeros and return
	return `#${padZero(r)}${padZero(g)}${padZero(b)}`;
}
