/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import StudentAdvisorGeneratedCourseOfferingReferenceManyToMany from '../Security/Acl/StudentAdvisorGeneratedCourseOfferingReferenceManyToMany';
import UserGeneratedCourseOfferingReferenceManyToMany from '../Security/Acl/UserGeneratedCourseOfferingReferenceManyToMany';
import VisitorsGeneratedCourseOfferingReferenceManyToMany from '../Security/Acl/VisitorsGeneratedCourseOfferingReferenceManyToMany';
import AdminGeneratedCourseOfferingReferenceManyToMany from '../Security/Acl/AdminGeneratedCourseOfferingReferenceManyToMany';
import InstitutionAdminGeneratedCourseOfferingReferenceManyToMany from '../Security/Acl/InstitutionAdminGeneratedCourseOfferingReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IGeneratedTermGeneratedCourseOfferingAttributes extends IModelAttributes {
	generatedTermId: string;
	generatedCourseOfferingId: string;

	generatedTerm: Models.GeneratedTermEntity | Models.IGeneratedTermEntityAttributes;
	generatedCourseOffering: Models.CourseOfferingEntity | Models.ICourseOfferingEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('GeneratedTermGeneratedCourseOffering')
export default class GeneratedTermGeneratedCourseOffering
	extends Model
	implements IGeneratedTermGeneratedCourseOfferingAttributes {
	public static acls: IAcl[] = [
		new StudentAdvisorGeneratedCourseOfferingReferenceManyToMany(),
		new UserGeneratedCourseOfferingReferenceManyToMany(),
		new VisitorsGeneratedCourseOfferingReferenceManyToMany(),
		new AdminGeneratedCourseOfferingReferenceManyToMany(),
		new InstitutionAdminGeneratedCourseOfferingReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public generatedTermId: string;

	@observable
	@attribute()
	public generatedCourseOfferingId: string;

	@observable
	@attribute({ isReference: true })
	public generatedTerm: Models.GeneratedTermEntity;

	@observable
	@attribute({ isReference: true })
	public generatedCourseOffering: Models.CourseOfferingEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IGeneratedTermGeneratedCourseOfferingAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.generatedTermId) {
				this.generatedTermId = attributes.generatedTermId;
			}
			if (attributes.generatedCourseOfferingId) {
				this.generatedCourseOfferingId = attributes.generatedCourseOfferingId;
			}

			if (attributes.generatedTerm) {
				if (attributes.generatedTerm instanceof Models.GeneratedTermEntity) {
					this.generatedTerm = attributes.generatedTerm;
					this.generatedTermId = attributes.generatedTerm.id;
				} else {
					this.generatedTerm = new Models.GeneratedTermEntity(attributes.generatedTerm);
					this.generatedTermId = this.generatedTerm.id;
				}
			} else if (attributes.generatedTermId !== undefined) {
				this.generatedTermId = attributes.generatedTermId;
			}

			if (attributes.generatedCourseOffering) {
				if (attributes.generatedCourseOffering instanceof Models.CourseOfferingEntity) {
					this.generatedCourseOffering = attributes.generatedCourseOffering;
					this.generatedCourseOfferingId = attributes.generatedCourseOffering.id;
				} else {
					this.generatedCourseOffering = new Models.CourseOfferingEntity(attributes.generatedCourseOffering);
					this.generatedCourseOfferingId = this.generatedCourseOffering.id;
				}
			} else if (attributes.generatedCourseOfferingId !== undefined) {
				this.generatedCourseOfferingId = attributes.generatedCourseOfferingId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
