/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import InstitutionAdminCourseGraduateLevelReferenceManyToMany from '../Security/Acl/InstitutionAdminCourseGraduateLevelReferenceManyToMany';
import StudentAdvisorCourseGraduateLevelReferenceManyToMany from '../Security/Acl/StudentAdvisorCourseGraduateLevelReferenceManyToMany';
import VisitorsCourseGraduateLevelReferenceManyToMany from '../Security/Acl/VisitorsCourseGraduateLevelReferenceManyToMany';
import AdminCourseGraduateLevelReferenceManyToMany from '../Security/Acl/AdminCourseGraduateLevelReferenceManyToMany';
import UserCourseGraduateLevelReferenceManyToMany from '../Security/Acl/UserCourseGraduateLevelReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IGraduateLevelCourseGraduateLevelAttributes extends IModelAttributes {
	graduateLevelId: string;
	courseGraduateLevelId: string;

	graduateLevel: Models.GraduateLevelEntity | Models.IGraduateLevelEntityAttributes;
	courseGraduateLevel: Models.CourseEntity | Models.ICourseEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('GraduateLevelCourseGraduateLevel')
export default class GraduateLevelCourseGraduateLevel
	extends Model
	implements IGraduateLevelCourseGraduateLevelAttributes {
	public static acls: IAcl[] = [
		new InstitutionAdminCourseGraduateLevelReferenceManyToMany(),
		new StudentAdvisorCourseGraduateLevelReferenceManyToMany(),
		new VisitorsCourseGraduateLevelReferenceManyToMany(),
		new AdminCourseGraduateLevelReferenceManyToMany(),
		new UserCourseGraduateLevelReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public graduateLevelId: string;

	@observable
	@attribute()
	public courseGraduateLevelId: string;

	@observable
	@attribute({ isReference: true })
	public graduateLevel: Models.GraduateLevelEntity;

	@observable
	@attribute({ isReference: true })
	public courseGraduateLevel: Models.CourseEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IGraduateLevelCourseGraduateLevelAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.graduateLevelId) {
				this.graduateLevelId = attributes.graduateLevelId;
			}
			if (attributes.courseGraduateLevelId) {
				this.courseGraduateLevelId = attributes.courseGraduateLevelId;
			}

			if (attributes.graduateLevel) {
				if (attributes.graduateLevel instanceof Models.GraduateLevelEntity) {
					this.graduateLevel = attributes.graduateLevel;
					this.graduateLevelId = attributes.graduateLevel.id;
				} else {
					this.graduateLevel = new Models.GraduateLevelEntity(attributes.graduateLevel);
					this.graduateLevelId = this.graduateLevel.id;
				}
			} else if (attributes.graduateLevelId !== undefined) {
				this.graduateLevelId = attributes.graduateLevelId;
			}

			if (attributes.courseGraduateLevel) {
				if (attributes.courseGraduateLevel instanceof Models.CourseEntity) {
					this.courseGraduateLevel = attributes.courseGraduateLevel;
					this.courseGraduateLevelId = attributes.courseGraduateLevel.id;
				} else {
					this.courseGraduateLevel = new Models.CourseEntity(attributes.courseGraduateLevel);
					this.courseGraduateLevelId = this.courseGraduateLevel.id;
				}
			} else if (attributes.courseGraduateLevelId !== undefined) {
				this.courseGraduateLevelId = attributes.courseGraduateLevelId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
