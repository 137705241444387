/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import StudentAdvisorProgramVersionEntity from 'Models/Security/Acl/StudentAdvisorProgramVersionEntity';
import AdminProgramVersionEntity from 'Models/Security/Acl/AdminProgramVersionEntity';
import InstitutionAdminProgramVersionEntity from 'Models/Security/Acl/InstitutionAdminProgramVersionEntity';
import UserProgramVersionEntity from 'Models/Security/Acl/UserProgramVersionEntity';
import VisitorsProgramVersionEntity from 'Models/Security/Acl/VisitorsProgramVersionEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { computed } from 'mobx';
// % protected region % [Add any further imports here] end

export interface IProgramVersionEntityAttributes extends IModelAttributes {
	versionDisplayName: string;
	versionLabel: string;
	commencingYear: number;
	description: string;
	duplicateDescription: string;
	status: Enums.programStatus;
	duration: string;
	requirementFulfilmentDescription: string;
	programRuleDescription: string;

	campuss: Array<
		| Models.CampusAssignedProgramVersion
		| Models.ICampusAssignedProgramVersionAttributes
	>;
	duplicatedFromId?: string;
	duplicatedFrom?: Models.ProgramVersionEntity | Models.IProgramVersionEntityAttributes;
	duplicatedProgramVersions: Array<
		| Models.ProgramVersionEntity
		| Models.IProgramVersionEntityAttributes
	>;
	programRules: Array<
		| Models.ProgramRuleEntity
		| Models.IProgramRuleEntityAttributes
	>;
	programStructures: Array<
		| Models.ProgramStructureEntity
		| Models.IProgramStructureEntityAttributes
	>;
	programId: string;
	program: Models.ProgramEntity | Models.IProgramEntityAttributes;
	rootProgramRules: Array<
		| Models.ProgramRuleEntity
		| Models.IProgramRuleEntityAttributes
	>;
	studyPeriodConfigs: Array<
		| Models.VersionStudyPeriodConfigEntity
		| Models.IVersionStudyPeriodConfigEntityAttributes
	>;
	studyPlans: Array<
		| Models.StudyPlanEntity
		| Models.IStudyPlanEntityAttributes
	>;
	termConfigs: Array<
		| Models.VersionTermConfigEntity
		| Models.IVersionTermConfigEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ProgramVersionEntity', 'Program Version')
// % protected region % [Customise your entity metadata here] end
export default class ProgramVersionEntity extends Model
	implements IProgramVersionEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new StudentAdvisorProgramVersionEntity(),
		new AdminProgramVersionEntity(),
		new InstitutionAdminProgramVersionEntity(),
		new UserProgramVersionEntity(),
		new VisitorsProgramVersionEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Version Display Name'] off begin
	@Validators.Required()
	@observable
	@attribute<ProgramVersionEntity, string>()
	@CRUD({
		name: 'Version Display Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public versionDisplayName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Version Display Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Version Label'] off begin
	/**
	 * Internal version label used by Administrators
	 */
	@Validators.Required()
	@observable
	@attribute<ProgramVersionEntity, string>()
	@CRUD({
		name: 'Version Label',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public versionLabel: string;
	// % protected region % [Modify props to the crud options here for attribute 'Version Label'] end

	// % protected region % [Modify props to the crud options here for attribute 'Commencing Year'] on begin
	/**
	 * Year in which this program version begins
	 */
	@observable
	@CRUD({
		name: 'Commencing Year',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	private _commencingYear: number;

	@Validators.Required()
	@Validators.Integer()
	@computed
	@attribute<ProgramVersionEntity, number>()
	public get commencingYear() {
		return this._commencingYear;
	}

	public set commencingYear(newCommencingYear: number) {
		const truncatedYear = newCommencingYear
			.toString()
			.substring(0, 4);

		const constrainedYear = parseInt(truncatedYear, 10);
		this._commencingYear = constrainedYear;
	}
	// % protected region % [Modify props to the crud options here for attribute 'Commencing Year'] end

	// % protected region % [Modify props to the crud options here for attribute 'Description'] off begin
	/**
	 * Details about the program.
	 */
	@Validators.Required()
	@observable
	@attribute<ProgramVersionEntity, string>()
	@CRUD({
		name: 'Description',
		displayType: 'textarea',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Duplicate Description'] off begin
	@Validators.Required()
	@observable
	@attribute<ProgramVersionEntity, string>()
	@CRUD({
		name: 'Duplicate Description',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public duplicateDescription: string;
	// % protected region % [Modify props to the crud options here for attribute 'Duplicate Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Status'] off begin
	@Validators.Required()
	@observable
	@attribute<ProgramVersionEntity, Enums.programStatus>()
	@CRUD({
		name: 'Status',
		displayType: 'enum-combobox',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.programStatusOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.programStatusOptions),
		displayFunction: (attr: Enums.programStatus) => Enums.programStatusOptions[attr],
	})
	public status: Enums.programStatus;
	// % protected region % [Modify props to the crud options here for attribute 'Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'Duration'] off begin
	/**
	 * Program Duration
	 */
	@Validators.Required()
	@observable
	@attribute<ProgramVersionEntity, string>()
	@CRUD({
		name: 'Duration',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public duration: string;
	// % protected region % [Modify props to the crud options here for attribute 'Duration'] end

	// % protected region % [Modify props to the crud options here for attribute 'Requirement Fulfilment Description'] off begin
	/**
	 * Description displayed on the Requirement Fulfilment Check page
	 */
	@Validators.Required()
	@observable
	@attribute<ProgramVersionEntity, string>()
	@CRUD({
		name: 'Requirement Fulfilment Description',
		displayType: 'textfield',
		order: 80,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public requirementFulfilmentDescription: string;
	// % protected region % [Modify props to the crud options here for attribute 'Requirement Fulfilment Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Program Rule Description'] off begin
	/**
	 * Description text for program version's configured program rules
	 */
	@Validators.Required()
	@observable
	@attribute<ProgramVersionEntity, string>()
	@CRUD({
		name: 'Program Rule Description',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public programRuleDescription: string;
	// % protected region % [Modify props to the crud options here for attribute 'Program Rule Description'] end

	/**
	 * Programs assigned to a Campus
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Campus'] off begin
		name: 'Campus',
		displayType: 'reference-multicombobox',
		order: 100,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.CampusAssignedProgramVersion,
		optionEqualFunc: makeJoinEqualsFunc('campusId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'programVersionEntity',
			oppositeEntityName: 'campusEntity',
			relationName: 'assignedProgramVersion',
			relationOppositeName: 'campus',
			entity: () => Models.ProgramVersionEntity,
			joinEntity: () => Models.CampusAssignedProgramVersion,
			oppositeEntity: () => Models.CampusEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Campus'] end
	})
	public campuss: Models.CampusAssignedProgramVersion[] = [];

	/**
	 * Track the source program version of a duplicated version
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Duplicated From'] off begin
		name: 'Duplicated From',
		displayType: 'reference-combobox',
		order: 110,
		referenceTypeFunc: () => Models.ProgramVersionEntity,
		// % protected region % [Modify props to the crud options here for reference 'Duplicated From'] end
	})
	public duplicatedFromId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public duplicatedFrom: Models.ProgramVersionEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Duplicated Program Version'] off begin
		name: 'Duplicated Program Versions',
		displayType: 'reference-multicombobox',
		order: 120,
		referenceTypeFunc: () => Models.ProgramVersionEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'duplicatedProgramVersions',
			oppositeEntity: () => Models.ProgramVersionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Duplicated Program Version'] end
	})
	public duplicatedProgramVersions: Models.ProgramVersionEntity[] = [];

	/**
	 * All program rules of a program version. All program rules should link to a program version
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Program Rule'] off begin
		name: 'Program Rules',
		displayType: 'reference-multicombobox',
		order: 130,
		referenceTypeFunc: () => Models.ProgramRuleEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'programRules',
			oppositeEntity: () => Models.ProgramRuleEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Program Rule'] end
	})
	public programRules: Models.ProgramRuleEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Program Structure'] off begin
		name: 'Program Structures',
		displayType: 'reference-multicombobox',
		order: 140,
		referenceTypeFunc: () => Models.ProgramStructureEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'programStructures',
			oppositeEntity: () => Models.ProgramStructureEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Program Structure'] end
	})
	public programStructures: Models.ProgramStructureEntity[] = [];

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Program'] off begin
		name: 'Program',
		displayType: 'reference-combobox',
		order: 150,
		referenceTypeFunc: () => Models.ProgramEntity,
		// % protected region % [Modify props to the crud options here for reference 'Program'] end
	})
	public programId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public program: Models.ProgramEntity;

	/**
	 * The top most program rule
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Root Program Rule'] off begin
		name: 'Root Program Rules',
		displayType: 'reference-multicombobox',
		order: 160,
		referenceTypeFunc: () => Models.ProgramRuleEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'rootProgramRules',
			oppositeEntity: () => Models.ProgramRuleEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Root Program Rule'] end
	})
	public rootProgramRules: Models.ProgramRuleEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Study Period Config'] off begin
		name: 'Study Period Configs',
		displayType: 'reference-multicombobox',
		order: 170,
		referenceTypeFunc: () => Models.VersionStudyPeriodConfigEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'studyPeriodConfigs',
			oppositeEntity: () => Models.VersionStudyPeriodConfigEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Study Period Config'] end
	})
	public studyPeriodConfigs: Models.VersionStudyPeriodConfigEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Study Plan'] off begin
		name: 'Study Plans',
		displayType: 'reference-multicombobox',
		order: 180,
		referenceTypeFunc: () => Models.StudyPlanEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'studyPlans',
			oppositeEntity: () => Models.StudyPlanEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Study Plan'] end
	})
	public studyPlans: Models.StudyPlanEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Term Config'] off begin
		name: 'Term Configs',
		displayType: 'reference-multicombobox',
		order: 190,
		referenceTypeFunc: () => Models.VersionTermConfigEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'termConfigs',
			oppositeEntity: () => Models.VersionTermConfigEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Term Config'] end
	})
	public termConfigs: Models.VersionTermConfigEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IProgramVersionEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IProgramVersionEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.versionDisplayName !== undefined) {
				this.versionDisplayName = attributes.versionDisplayName;
			}
			if (attributes.versionLabel !== undefined) {
				this.versionLabel = attributes.versionLabel;
			}
			if (attributes.commencingYear !== undefined) {
				this.commencingYear = attributes.commencingYear;
			}
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.duplicateDescription !== undefined) {
				this.duplicateDescription = attributes.duplicateDescription;
			}
			if (attributes.status !== undefined) {
				this.status = attributes.status;
			}
			if (attributes.duration !== undefined) {
				this.duration = attributes.duration;
			}
			if (attributes.requirementFulfilmentDescription !== undefined) {
				this.requirementFulfilmentDescription = attributes.requirementFulfilmentDescription;
			}
			if (attributes.programRuleDescription !== undefined) {
				this.programRuleDescription = attributes.programRuleDescription;
			}
			if (attributes.campuss !== undefined && Array.isArray(attributes.campuss)) {
				for (const model of attributes.campuss) {
					if (model instanceof Models.CampusAssignedProgramVersion) {
						this.campuss.push(model);
					} else {
						this.campuss.push(new Models.CampusAssignedProgramVersion(model));
					}
				}
			}
			if (attributes.duplicatedProgramVersions !== undefined && Array.isArray(attributes.duplicatedProgramVersions)) {
				for (const model of attributes.duplicatedProgramVersions) {
					if (model instanceof Models.ProgramVersionEntity) {
						this.duplicatedProgramVersions.push(model);
					} else {
						this.duplicatedProgramVersions.push(new Models.ProgramVersionEntity(model));
					}
				}
			}
			if (attributes.duplicatedFromId !== undefined) {
				this.duplicatedFromId = attributes.duplicatedFromId;
			}
			if (attributes.duplicatedFrom !== undefined) {
				if (attributes.duplicatedFrom === null) {
					this.duplicatedFrom = attributes.duplicatedFrom;
				} else if (attributes.duplicatedFrom instanceof Models.ProgramVersionEntity) {
					this.duplicatedFrom = attributes.duplicatedFrom;
					this.duplicatedFromId = attributes.duplicatedFrom.id;
				} else {
					this.duplicatedFrom = new Models.ProgramVersionEntity(attributes.duplicatedFrom);
					this.duplicatedFromId = this.duplicatedFrom.id;
				}
			}
			if (attributes.programRules !== undefined && Array.isArray(attributes.programRules)) {
				for (const model of attributes.programRules) {
					if (model instanceof Models.ProgramRuleEntity) {
						this.programRules.push(model);
					} else {
						this.programRules.push(new Models.ProgramRuleEntity(model));
					}
				}
			}
			if (attributes.programStructures !== undefined && Array.isArray(attributes.programStructures)) {
				for (const model of attributes.programStructures) {
					if (model instanceof Models.ProgramStructureEntity) {
						this.programStructures.push(model);
					} else {
						this.programStructures.push(new Models.ProgramStructureEntity(model));
					}
				}
			}
			if (attributes.programId !== undefined) {
				this.programId = attributes.programId;
			}
			if (attributes.program !== undefined) {
				if (attributes.program === null) {
					this.program = attributes.program;
				} else if (attributes.program instanceof Models.ProgramEntity) {
					this.program = attributes.program;
					this.programId = attributes.program.id;
				} else {
					this.program = new Models.ProgramEntity(attributes.program);
					this.programId = this.program.id;
				}
			}
			if (attributes.rootProgramRules !== undefined && Array.isArray(attributes.rootProgramRules)) {
				for (const model of attributes.rootProgramRules) {
					if (model instanceof Models.ProgramRuleEntity) {
						this.rootProgramRules.push(model);
					} else {
						this.rootProgramRules.push(new Models.ProgramRuleEntity(model));
					}
				}
			}
			if (attributes.studyPeriodConfigs !== undefined && Array.isArray(attributes.studyPeriodConfigs)) {
				for (const model of attributes.studyPeriodConfigs) {
					if (model instanceof Models.VersionStudyPeriodConfigEntity) {
						this.studyPeriodConfigs.push(model);
					} else {
						this.studyPeriodConfigs.push(new Models.VersionStudyPeriodConfigEntity(model));
					}
				}
			}
			if (attributes.studyPlans !== undefined && Array.isArray(attributes.studyPlans)) {
				for (const model of attributes.studyPlans) {
					if (model instanceof Models.StudyPlanEntity) {
						this.studyPlans.push(model);
					} else {
						this.studyPlans.push(new Models.StudyPlanEntity(model));
					}
				}
			}
			if (attributes.termConfigs !== undefined && Array.isArray(attributes.termConfigs)) {
				for (const model of attributes.termConfigs) {
					if (model instanceof Models.VersionTermConfigEntity) {
						this.termConfigs.push(model);
					} else {
						this.termConfigs.push(new Models.VersionTermConfigEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		campuss {
			${Models.CampusAssignedProgramVersion.getAttributes().join('\n')}
			campus {
				${Models.CampusEntity.getAttributes().join('\n')}
			}
		}
		duplicatedProgramVersions {
			${Models.ProgramVersionEntity.getAttributes().join('\n')}
		}
		duplicatedFrom {
			${Models.ProgramVersionEntity.getAttributes().join('\n')}
		}
		programRules {
			${Models.ProgramRuleEntity.getAttributes().join('\n')}
		}
		programStructures {
			${Models.ProgramStructureEntity.getAttributes().join('\n')}
		}
		program {
			${Models.ProgramEntity.getAttributes().join('\n')}
		}
		rootProgramRules {
			${Models.ProgramRuleEntity.getAttributes().join('\n')}
		}
		studyPeriodConfigs {
			${Models.VersionStudyPeriodConfigEntity.getAttributes().join('\n')}
		}
		studyPlans {
			${Models.StudyPlanEntity.getAttributes().join('\n')}
		}
		termConfigs {
			${Models.VersionTermConfigEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			campuss: {},
			duplicatedProgramVersions: {},
			programRules: {},
			programStructures: {},
			rootProgramRules: {},
			studyPeriodConfigs: {},
			studyPlans: {},
			termConfigs: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'campuss',
							'duplicatedProgramVersions',
							'programRules',
							'rootProgramRules',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.versionDisplayName;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	public clone() {
		const versionCopy = new ProgramVersionEntity({
			...this,
			// explicitly set properties using getter/setters
			commencingYear: this.commencingYear,
		});

		return versionCopy;
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ProgramVersionEntity.prototype, 'created');
CRUD(modifiedAttr)(ProgramVersionEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
