/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AdminStudyPlanEntity from 'Models/Security/Acl/AdminStudyPlanEntity';
import InstitutionAdminStudyPlanEntity from 'Models/Security/Acl/InstitutionAdminStudyPlanEntity';
import StudentAdvisorStudyPlanEntity from 'Models/Security/Acl/StudentAdvisorStudyPlanEntity';
import VisitorsStudyPlanEntity from 'Models/Security/Acl/VisitorsStudyPlanEntity';
import UserStudyPlanEntity from 'Models/Security/Acl/UserStudyPlanEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { DataIndex } from "Util/DataIndexUtils";
// % protected region % [Add any further imports here] end

export interface IStudyPlanEntityAttributes extends IModelAttributes {
	name: string;
	commencement: Date;

	generatedPlan?: Models.GeneratedPlanEntity |
		Models.IGeneratedPlanEntityAttributes;
	planSelections: Array<
		| Models.PlanSelectionEntity
		| Models.IPlanSelectionEntityAttributes
	>;
	studyPlanRequirements: Array<
		| Models.RequiringStudyPlanStudyPlanRequirement
		| Models.IRequiringStudyPlanStudyPlanRequirementAttributes
	>;
	campusId: string;
	campus: Models.CampusEntity | Models.ICampusEntityAttributes;
	institutionAdminId?: string;
	institutionAdmin?: Models.InstitutionAdminEntity | Models.IInstitutionAdminEntityAttributes;
	programStructureId?: string;
	programStructure?: Models.ProgramStructureEntity | Models.IProgramStructureEntityAttributes;
	programVersionId: string;
	programVersion: Models.ProgramVersionEntity | Models.IProgramVersionEntityAttributes;
	studentId?: string;
	student?: Models.UserEntity | Models.IUserEntityAttributes;
	studentAdvisorId?: string;
	studentAdvisor?: Models.StudentAdvisorEntity | Models.IStudentAdvisorEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('StudyPlanEntity', 'Study Plan')
// % protected region % [Customise your entity metadata here] end
export default class StudyPlanEntity extends Model
	implements IStudyPlanEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AdminStudyPlanEntity(),
		new InstitutionAdminStudyPlanEntity(),
		new StudentAdvisorStudyPlanEntity(),
		new VisitorsStudyPlanEntity(),
		new UserStudyPlanEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@Validators.Required()
	@observable
	@attribute<StudyPlanEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Commencement'] off begin
	/**
	 * Date of plan commencement
	 */
	@Validators.Required()
	@observable
	@attribute<StudyPlanEntity, Date>({ serialise: (e, a) => AttrUtils.serialiseDate(a) })
	@CRUD({
		name: 'Commencement',
		displayType: 'datepicker',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: attr => AttrUtils.standardiseDate(attr, { serialiseAs: 'date' }),
	})
	public commencement: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Commencement'] end

	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Generated Plan'] off begin
		name: 'Generated Plan',
		displayType: 'displayfield',
		order: 30,
		inputProps: {
			displayFunction: (model?: Models.GeneratedPlanEntity) => model ? model.getDisplayName() : null,
		},
		// % protected region % [Modify props to the crud options here for reference 'Generated Plan'] end
	})
	public generatedPlan?: Models.GeneratedPlanEntity;

	/**
	 * Selected instances of courses within a plan, these may be selected Course Offerings or Completed Courses
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Plan Selection'] off begin
		name: 'Plan Selections',
		displayType: 'reference-multicombobox',
		order: 40,
		referenceTypeFunc: () => Models.PlanSelectionEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'planSelections',
			oppositeEntity: () => Models.PlanSelectionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Plan Selection'] end
	})
	public planSelections: Models.PlanSelectionEntity[] = [];

	/**
	 * Relationship signifying the Requirement has been selected for a given Component
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Study Plan Requirement'] off begin
		name: 'Study Plan Requirement',
		displayType: 'reference-multicombobox',
		order: 50,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.RequiringStudyPlanStudyPlanRequirement,
		optionEqualFunc: makeJoinEqualsFunc('studyPlanRequirementId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'studyPlanEntity',
			oppositeEntityName: 'requirementEntity',
			relationName: 'requiringStudyPlan',
			relationOppositeName: 'studyPlanRequirement',
			entity: () => Models.StudyPlanEntity,
			joinEntity: () => Models.RequiringStudyPlanStudyPlanRequirement,
			oppositeEntity: () => Models.RequirementEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Study Plan Requirement'] end
	})
	public studyPlanRequirements: Models.RequiringStudyPlanStudyPlanRequirement[] = [];

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Campus'] off begin
		name: 'Campus',
		displayType: 'reference-combobox',
		order: 60,
		referenceTypeFunc: () => Models.CampusEntity,
		// % protected region % [Modify props to the crud options here for reference 'Campus'] end
	})
	public campusId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public campus: Models.CampusEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Institution Admin'] off begin
		name: 'Institution Admin',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.InstitutionAdminEntity,
		// % protected region % [Modify props to the crud options here for reference 'Institution Admin'] end
	})
	public institutionAdminId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public institutionAdmin: Models.InstitutionAdminEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Program Structure'] off begin
		name: 'Program Structure',
		displayType: 'reference-combobox',
		order: 80,
		referenceTypeFunc: () => Models.ProgramStructureEntity,
		// % protected region % [Modify props to the crud options here for reference 'Program Structure'] end
	})
	public programStructureId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public programStructure: Models.ProgramStructureEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Program Version'] off begin
		name: 'Program Version',
		displayType: 'reference-combobox',
		order: 90,
		referenceTypeFunc: () => Models.ProgramVersionEntity,
		// % protected region % [Modify props to the crud options here for reference 'Program Version'] end
	})
	public programVersionId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public programVersion: Models.ProgramVersionEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Student'] off begin
		name: 'Student',
		displayType: 'reference-combobox',
		order: 100,
		referenceTypeFunc: () => Models.UserEntity,
		// % protected region % [Modify props to the crud options here for reference 'Student'] end
	})
	public studentId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public student: Models.UserEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Student Advisor'] off begin
		name: 'Student Advisor',
		displayType: 'reference-combobox',
		order: 110,
		referenceTypeFunc: () => Models.StudentAdvisorEntity,
		// % protected region % [Modify props to the crud options here for reference 'Student Advisor'] end
	})
	public studentAdvisorId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public studentAdvisor: Models.StudentAdvisorEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IStudyPlanEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IStudyPlanEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.commencement !== undefined) {
				if (attributes.commencement === null) {
					this.commencement = attributes.commencement;
				} else {
					this.commencement = moment(attributes.commencement).toDate();
				}
			}
			if (attributes.generatedPlan !== undefined) {
				if (attributes.generatedPlan === null) {
					this.generatedPlan = attributes.generatedPlan;
				} else if (attributes.generatedPlan instanceof Models.GeneratedPlanEntity) {
					this.generatedPlan = attributes.generatedPlan;
				} else {
					this.generatedPlan = new Models.GeneratedPlanEntity(attributes.generatedPlan);
				}
			}
			if (attributes.planSelections !== undefined && Array.isArray(attributes.planSelections)) {
				for (const model of attributes.planSelections) {
					if (model instanceof Models.PlanSelectionEntity) {
						this.planSelections.push(model);
					} else {
						this.planSelections.push(new Models.PlanSelectionEntity(model));
					}
				}
			}
			if (attributes.studyPlanRequirements !== undefined && Array.isArray(attributes.studyPlanRequirements)) {
				for (const model of attributes.studyPlanRequirements) {
					if (model instanceof Models.RequiringStudyPlanStudyPlanRequirement) {
						this.studyPlanRequirements.push(model);
					} else {
						this.studyPlanRequirements.push(new Models.RequiringStudyPlanStudyPlanRequirement(model));
					}
				}
			}
			if (attributes.campusId !== undefined) {
				this.campusId = attributes.campusId;
			}
			if (attributes.campus !== undefined) {
				if (attributes.campus === null) {
					this.campus = attributes.campus;
				} else if (attributes.campus instanceof Models.CampusEntity) {
					this.campus = attributes.campus;
					this.campusId = attributes.campus.id;
				} else {
					this.campus = new Models.CampusEntity(attributes.campus);
					this.campusId = this.campus.id;
				}
			}
			if (attributes.institutionAdminId !== undefined) {
				this.institutionAdminId = attributes.institutionAdminId;
			}
			if (attributes.institutionAdmin !== undefined) {
				if (attributes.institutionAdmin === null) {
					this.institutionAdmin = attributes.institutionAdmin;
				} else if (attributes.institutionAdmin instanceof Models.InstitutionAdminEntity) {
					this.institutionAdmin = attributes.institutionAdmin;
					this.institutionAdminId = attributes.institutionAdmin.id;
				} else {
					this.institutionAdmin = new Models.InstitutionAdminEntity(attributes.institutionAdmin);
					this.institutionAdminId = this.institutionAdmin.id;
				}
			}
			if (attributes.programStructureId !== undefined) {
				this.programStructureId = attributes.programStructureId;
			}
			if (attributes.programStructure !== undefined) {
				if (attributes.programStructure === null) {
					this.programStructure = attributes.programStructure;
				} else if (attributes.programStructure instanceof Models.ProgramStructureEntity) {
					this.programStructure = attributes.programStructure;
					this.programStructureId = attributes.programStructure.id;
				} else {
					this.programStructure = new Models.ProgramStructureEntity(attributes.programStructure);
					this.programStructureId = this.programStructure.id;
				}
			}
			if (attributes.programVersionId !== undefined) {
				this.programVersionId = attributes.programVersionId;
			}
			if (attributes.programVersion !== undefined) {
				if (attributes.programVersion === null) {
					this.programVersion = attributes.programVersion;
				} else if (attributes.programVersion instanceof Models.ProgramVersionEntity) {
					this.programVersion = attributes.programVersion;
					this.programVersionId = attributes.programVersion.id;
				} else {
					this.programVersion = new Models.ProgramVersionEntity(attributes.programVersion);
					this.programVersionId = this.programVersion.id;
				}
			}
			if (attributes.studentId !== undefined) {
				this.studentId = attributes.studentId;
			}
			if (attributes.student !== undefined) {
				if (attributes.student === null) {
					this.student = attributes.student;
				} else if (attributes.student instanceof Models.UserEntity) {
					this.student = attributes.student;
					this.studentId = attributes.student.id;
				} else {
					this.student = new Models.UserEntity(attributes.student);
					this.studentId = this.student.id;
				}
			}
			if (attributes.studentAdvisorId !== undefined) {
				this.studentAdvisorId = attributes.studentAdvisorId;
			}
			if (attributes.studentAdvisor !== undefined) {
				if (attributes.studentAdvisor === null) {
					this.studentAdvisor = attributes.studentAdvisor;
				} else if (attributes.studentAdvisor instanceof Models.StudentAdvisorEntity) {
					this.studentAdvisor = attributes.studentAdvisor;
					this.studentAdvisorId = attributes.studentAdvisor.id;
				} else {
					this.studentAdvisor = new Models.StudentAdvisorEntity(attributes.studentAdvisor);
					this.studentAdvisorId = this.studentAdvisor.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		studyPlanRequirements {
			${Models.RequiringStudyPlanStudyPlanRequirement.getAttributes().join('\n')}
			studyPlanRequirement {
				${Models.RequirementEntity.getAttributes().join('\n')}
			}
		}
		generatedPlan {
			${Models.GeneratedPlanEntity.getAttributes().join('\n')}
		}
		planSelections {
			${Models.PlanSelectionEntity.getAttributes().join('\n')}
		}
		campus {
			${Models.CampusEntity.getAttributes().join('\n')}
		}
		institutionAdmin {
			${Models.InstitutionAdminEntity.getAttributes().join('\n')}
		}
		programStructure {
			${Models.ProgramStructureEntity.getAttributes().join('\n')}
		}
		programVersion {
			${Models.ProgramVersionEntity.getAttributes().join('\n')}
		}
		student {
			${Models.UserEntity.getAttributes().join('\n')}
		}
		studentAdvisor {
			${Models.StudentAdvisorEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			studyPlanRequirements: {},
			planSelections: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'studyPlanRequirements',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	public getSelectedRequirements() {
		if (!this.studyPlanRequirements) {
			throw new Error('Cannot determine selected requirements from plan selections - unassigned property');
		}
		return this.studyPlanRequirements
			.map(planRequirementJoin => planRequirementJoin.studyPlanRequirementId);
	}

	public getPlannedOfferingsForCourse(courseId: string, offeringsById: DataIndex<Models.CourseOfferingEntity>) {
		if (!this.planSelections) {
			throw new Error('Cannot determine planned courses from planned offerings - unassigned property');
		}
		return this.planSelections
			.filter(planSelection => planSelection.courseId === courseId && planSelection.completionStatus === 'INCOMPLETE' && planSelection.courseOfferingId !== undefined)
			.map(planSelection => offeringsById[planSelection.courseOfferingId!])
	}
	
	public getIncompletePlanSelections() {
		if (!this.planSelections) {
			throw new Error('Cannot determine incomplete study plan courses - unassigned property');
		}
		return this.planSelections
			// Ignoring selections that have no course offerings
			.filter(planSelection => planSelection.completionStatus === 'INCOMPLETE' && planSelection.courseOfferingId);
	}

	public getCompletedPlanSelections() {
		if (!this.planSelections) {
			throw new Error('Cannot determine completed study plan courses - unassigned property');
		}
		return this.planSelections
			.filter(planSelection => planSelection.completionStatus === 'COMPLETE');
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(StudyPlanEntity.prototype, 'created');
CRUD(modifiedAttr)(StudyPlanEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
