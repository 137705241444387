/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import InstitutionAdminProgramEntity from 'Models/Security/Acl/InstitutionAdminProgramEntity';
import UserProgramEntity from 'Models/Security/Acl/UserProgramEntity';
import StudentAdvisorProgramEntity from 'Models/Security/Acl/StudentAdvisorProgramEntity';
import VisitorsProgramEntity from 'Models/Security/Acl/VisitorsProgramEntity';
import AdminProgramEntity from 'Models/Security/Acl/AdminProgramEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IProgramEntityAttributes extends IModelAttributes {
	title: string;
	formalAbbreviation: string;
	sourceProgramId: string;
	displayName: string;
	code: string;

	programVersions: Array<
		| Models.ProgramVersionEntity
		| Models.IProgramVersionEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ProgramEntity', 'Program')
// % protected region % [Customise your entity metadata here] end
export default class ProgramEntity extends Model
	implements IProgramEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new InstitutionAdminProgramEntity(),
		new UserProgramEntity(),
		new StudentAdvisorProgramEntity(),
		new VisitorsProgramEntity(),
		new AdminProgramEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Title'] off begin
	@Validators.Required()
	@observable
	@attribute<ProgramEntity, string>()
	@CRUD({
		name: 'Title',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public title: string;
	// % protected region % [Modify props to the crud options here for attribute 'Title'] end

	// % protected region % [Modify props to the crud options here for attribute 'Formal Abbreviation'] on begin
	@Validators.Required()
	@observable
	@attribute<ProgramEntity, string>()
	@CRUD({
		name: 'Formal abbreviation',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public formalAbbreviation: string;
	// % protected region % [Modify props to the crud options here for attribute 'Formal Abbreviation'] end

	// % protected region % [Modify props to the crud options here for attribute 'Source Program Id'] on begin
	@Validators.Required()
	@Validators.Numeric()
	@observable
	@attribute<ProgramEntity, string>()
	@CRUD({
		name: 'Source Program Id',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public sourceProgramId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Source Program Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'Display Name'] on begin
	@Validators.Required()
	@observable
	@attribute<ProgramEntity, string>()
	@CRUD({
		name: 'Display title',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public displayName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Display Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Code'] off begin
	@Validators.Required()
	@observable
	@attribute<ProgramEntity, string>()
	@CRUD({
		name: 'Code',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public code: string;
	// % protected region % [Modify props to the crud options here for attribute 'Code'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Program Version'] off begin
		name: 'Program Versions',
		displayType: 'reference-multicombobox',
		order: 60,
		referenceTypeFunc: () => Models.ProgramVersionEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'programVersions',
			oppositeEntity: () => Models.ProgramVersionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Program Version'] end
	})
	public programVersions: Models.ProgramVersionEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IProgramEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IProgramEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.title !== undefined) {
				this.title = attributes.title;
			}
			if (attributes.formalAbbreviation !== undefined) {
				this.formalAbbreviation = attributes.formalAbbreviation;
			}
			if (attributes.sourceProgramId !== undefined) {
				this.sourceProgramId = attributes.sourceProgramId;
			}
			if (attributes.displayName !== undefined) {
				this.displayName = attributes.displayName;
			}
			if (attributes.code !== undefined) {
				this.code = attributes.code;
			}
			if (attributes.programVersions !== undefined && Array.isArray(attributes.programVersions)) {
				for (const model of attributes.programVersions) {
					if (model instanceof Models.ProgramVersionEntity) {
						this.programVersions.push(model);
					} else {
						this.programVersions.push(new Models.ProgramVersionEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		programVersions {
			${Models.ProgramVersionEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			programVersions: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.title;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ProgramEntity.prototype, 'created');
CRUD(modifiedAttr)(ProgramEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
