/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import UserCourseCampusReferenceManyToMany from '../Security/Acl/UserCourseCampusReferenceManyToMany';
import VisitorsCourseCampusReferenceManyToMany from '../Security/Acl/VisitorsCourseCampusReferenceManyToMany';
import StudentAdvisorCourseCampusReferenceManyToMany from '../Security/Acl/StudentAdvisorCourseCampusReferenceManyToMany';
import AdminCourseCampusReferenceManyToMany from '../Security/Acl/AdminCourseCampusReferenceManyToMany';
import InstitutionAdminCourseCampusReferenceManyToMany from '../Security/Acl/InstitutionAdminCourseCampusReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ICampusCourseCampusAttributes extends IModelAttributes {
	campusId: string;
	courseCampusId: string;

	campus: Models.CampusEntity | Models.ICampusEntityAttributes;
	courseCampus: Models.CourseEntity | Models.ICourseEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('CampusCourseCampus')
export default class CampusCourseCampus
	extends Model
	implements ICampusCourseCampusAttributes {
	public static acls: IAcl[] = [
		new UserCourseCampusReferenceManyToMany(),
		new VisitorsCourseCampusReferenceManyToMany(),
		new StudentAdvisorCourseCampusReferenceManyToMany(),
		new AdminCourseCampusReferenceManyToMany(),
		new InstitutionAdminCourseCampusReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public campusId: string;

	@observable
	@attribute()
	public courseCampusId: string;

	@observable
	@attribute({ isReference: true })
	public campus: Models.CampusEntity;

	@observable
	@attribute({ isReference: true })
	public courseCampus: Models.CourseEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<ICampusCourseCampusAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.campusId) {
				this.campusId = attributes.campusId;
			}
			if (attributes.courseCampusId) {
				this.courseCampusId = attributes.courseCampusId;
			}

			if (attributes.campus) {
				if (attributes.campus instanceof Models.CampusEntity) {
					this.campus = attributes.campus;
					this.campusId = attributes.campus.id;
				} else {
					this.campus = new Models.CampusEntity(attributes.campus);
					this.campusId = this.campus.id;
				}
			} else if (attributes.campusId !== undefined) {
				this.campusId = attributes.campusId;
			}

			if (attributes.courseCampus) {
				if (attributes.courseCampus instanceof Models.CourseEntity) {
					this.courseCampus = attributes.courseCampus;
					this.courseCampusId = attributes.courseCampus.id;
				} else {
					this.courseCampus = new Models.CourseEntity(attributes.courseCampus);
					this.courseCampusId = this.courseCampus.id;
				}
			} else if (attributes.courseCampusId !== undefined) {
				this.courseCampusId = attributes.courseCampusId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
